import React,{useState} from "react";
import bannerData from "../../data/banner/banner-twenty-four.json";
import BannerTwentyFourSingle from "../../components/banner/BannerTwentyFourSingle.js";
import { getBanner } from "../../Services/API/Get";
import LoadingSpinner from "../../components/LoadingSpinner/loadingSpinner";

const BannerTwentyFour = ({ spaceTopClass, spaceBottomClass }) => {

  const [caption, setcaption] = useState([]);
  const [lopen, setlopen] = useState(false);

  const captionGet = () => {
    setlopen(true);
    getBanner()
    .then((res) => {
      setlopen(false);
      console.log("about>>",res.data);
      setcaption(res.data);
      // setLogo(res.data[res.data.length - 1].image)
      // this.setState({
      //   logo: res.data[res.data.length - 1].image,
      // });
    })
    .catch((error) => {
      setlopen(false);
      console.error(error);
    });
  };



  React.useEffect(() => {
    captionGet();
    // coverGet();
  }, []);

  return (
    <>
     <LoadingSpinner open={lopen} />
     <div
      className={`banner-area ${spaceTopClass ? spaceTopClass : ""}  ${
        spaceBottomClass ? spaceBottomClass : ""
      }`}
    >
      <div className="container">
        <div className="row">
          {caption.length > 0 &&
            caption.map((single, key) => {
              return (
                <BannerTwentyFourSingle
                  data={single}
                  key={key}
                  spaceBottomClass="mb-30"
                />
              );
            })}
        </div>
      </div>
    </div>
    </>
 
  );
};

export default BannerTwentyFour;
