import PropTypes from "prop-types";
import React, { useState } from "react";
import Swiper from "react-id-swiper";
import testimonialData from "../../data/testimonial/testimonial-one.json";
import TestimonialOneSingle from "../../components/testimonial/TestimonialOneSingle.js";
import { getTestimonial } from "../../Services/API/Get";
import LoadingSpinner from "../../components/LoadingSpinner/loadingSpinner";
import { Link } from "react-router-dom";

const TestimonialOne = ({
  spaceTopClass,
  spaceBottomClass,
  spaceLeftClass,
  spaceRightClass,
  bgColorClass,
  testimonialClass,
  backgroundImage,
  sliderClass
}) => {
  // swiper slider settings
  const settings = {
    slidesPerView: 1,
    loop: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    }
  };

  const [testimonial, settestimonial] = useState([]);
  const [lopen, setlopen] = useState(false);
  var matches = window.matchMedia("(max-width: 600px)");

  const TestiGet = () => {
    setlopen(true);
    getTestimonial()
      .then((res) => {
        setlopen(false);
        console.log("about>>", res.data);
        settestimonial(res.data);
        // setLogo(res.data[res.data.length - 1].image)
        // this.setState({
        //   logo: res.data[res.data.length - 1].image,
        // });
      })
      .catch((error) => {
        setlopen(false);
        console.error(error);
      });
  };



  React.useEffect(() => {
    TestiGet();
    // coverGet();
  }, []);

  return (
    <>
      <LoadingSpinner open={lopen} />

      <Link to={process.env.PUBLIC_URL + "/shop-grid-standard"}>
        <div
          className={`single-slider-2 slider-height-22 d-flex align-items-center bg-img ${sliderClass ? sliderClass : ""
            }`}
          // style={{ backgroundImage: `url(${process.env.PUBLIC_URL + data.image})` }}
          style={{
            backgroundImage: matches.matches === false ? `url(${process.env.PUBLIC_URL}${"/assets/img/banner/cover-1.png"})` : `url(${process.env.PUBLIC_URL}${"/assets/img/banner/cover-2.png"})`
            , height: matches.matches === false ? "" : "650px", backgroundSize: matches.matches === false ? "fit-content" : "fit-content", backgroundRepeat: "no-repeat"
          }}
        >
          <div className="container">
            <div className="row">


              {/* <img
                  src={process.env.PUBLIC_URL + "/assets/img/banner/cover-1.png"}
                  alt=""
                  // style={{width:"90vw"}}
                  // className="img-fluid"
                /> */}


            </div>
          </div>
        </div>
      </Link>


    </>

  );
};

TestimonialOne.propTypes = {
  bgColorClass: PropTypes.string,
  spaceBottomClass: PropTypes.string,
  spaceLeftClass: PropTypes.string,
  spaceRightClass: PropTypes.string,
  spaceTopClass: PropTypes.string,
  testimonialClass: PropTypes.string
};

export default TestimonialOne;
