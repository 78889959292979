import PropTypes from "prop-types";
import React,{useState} from "react";
import Swiper from "react-id-swiper";
import testimonialData from "../../data/testimonial/testimonial-one.json";
import TestimonialOneSingle from "../../components/testimonial/TestimonialOneSingle.js";
import { getTestimonial } from "../../Services/API/Get";
import LoadingSpinner from "../../components/LoadingSpinner/loadingSpinner";

const TestimonialOne = ({
  spaceTopClass,
  spaceBottomClass,
  spaceLeftClass,
  spaceRightClass,
  bgColorClass,
  testimonialClass,
  backgroundImage
}) => {
  // swiper slider settings
  const settings = {
    slidesPerView: 1,
    loop: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    }
  };

  const [testimonial, settestimonial] = useState([]);
  const [lopen, setlopen] = useState(false);

  const TestiGet = () => {
    setlopen(true);
    getTestimonial()
    .then((res) => {
      setlopen(false);
      console.log("about>>",res.data);
      settestimonial(res.data);
      // setLogo(res.data[res.data.length - 1].image)
      // this.setState({
      //   logo: res.data[res.data.length - 1].image,
      // });
    })
    .catch((error) => {
      setlopen(false);
      console.error(error);
    });
  };



  React.useEffect(() => {
    TestiGet();
    // coverGet();
  }, []);

  return (
    <>
     <LoadingSpinner open={lopen} />

     {testimonial.length > 0 && testimonial ? 
        <div
        className={`testimonial-area ${spaceTopClass ? spaceTopClass : ""}  ${
          spaceBottomClass ? spaceBottomClass : ""
        } ${spaceLeftClass ? spaceLeftClass : ""}  ${
          spaceRightClass ? spaceRightClass : ""
        } ${bgColorClass ? bgColorClass : ""}`}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-10 ml-auto mr-auto">
              <div className="testimonial-active nav-style-1 nav-testi-style">
                <Swiper {...settings}>
                  {testimonial &&
                    testimonial.map((single, key) => {
                      return (
                        <TestimonialOneSingle
                          data={single}
                          key={key}
                          sliderClass="swiper-slide"
                          testimonialClass={testimonialClass}
                        />
                      );
                    })}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
    
    :
    <></>
    
    }
  
    </>
  
  );
};

TestimonialOne.propTypes = {
  bgColorClass: PropTypes.string,
  spaceBottomClass: PropTypes.string,
  spaceLeftClass: PropTypes.string,
  spaceRightClass: PropTypes.string,
  spaceTopClass: PropTypes.string,
  testimonialClass: PropTypes.string
};

export default TestimonialOne;
