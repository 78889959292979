import PropTypes from "prop-types";
import React, { useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import MenuCart from "./sub-components/MenuCart";
import { deleteFromCart } from "../../redux/actions/cartActions";
import { getCartList } from "../../Services/API/Get";
import CartContext from "../../Context/CartContext";
import AuthContext from "../../Context/AuthContext";

const IconGroup = ({
  currency,
  cartData,
  wishlistData,
  compareData,
  deleteFromCart,
  iconWhiteClass
}) => {
  const [total, setTotal] = useState(0);
  const [copen, setcopen] = useState(false);
  const { cart, setCart } = useContext(CartContext);
  const { user, setUser } = useContext(AuthContext);
  const history = useHistory();


  React.useEffect(() => {
    getCart();
    setcopen(false);
    console.log("test")

  }, [copen]);

  const getCart = () => {
    getCartList()
      .then((res) => {
        console.log("get Cart >>>>>", res)
        let m = 0;
        const abc = res.data?.map((e) => {
          m = m + e.qty * e.discountedprice;
        });
        // setArray(res.data);
        // setTotal(res.data.length)
        // this.setState({
        //   array: res.data,
        //   total: m,
        // });
      })
      .catch((err) => {
        // setTotal(0)
        // this.setState({
        //   color2: "#645bff",
        //   alertClass: "success",
        //   msg: `${this.state.productData?.displayName} product add failed`,
        //   open_toast: true,
        // });
      });
  }
  const handleClick = e => {
    e.currentTarget.nextSibling.classList.toggle("active");
  };

  const triggerMobileMenu = () => {
    const offcanvasMobileMenu = document.querySelector(
      "#offcanvas-mobile-menu"
    );
    offcanvasMobileMenu.classList.add("active");
  };

  return (
    <div
      className={`header-right-wrap ${iconWhiteClass ? iconWhiteClass : ""}`}
    >
      <div className="same-style header-search d-none d-lg-block">
        <button className="search-active" onClick={e => handleClick(e)}>
          {/* <i className="pe-7s-search" /> */}
        </button>
        <div className="search-content">
          {/* <form action="#">
            <input type="text" placeholder="Search" />
            <button className="button-search">
              <i className="pe-7s-search" />
            </button>
          </form> */}
        </div>
      </div>
      {/* <div className="same-style account-setting d-none d-lg-block">
        <button
          className="account-setting-active"
          onClick={e => handleClick(e)}
        >
          <i className="pe-7s-user-female" />
        </button>
        <div className="account-dropdown">
          <ul>
            {user !== null ?
              <>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/my-account"}>
                    my account
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/order-summary"}>
                    Order Summary
                  </Link>
                </li>
                <li
                  onClick={() => {
                    window.localStorage.removeItem(
                      `${window.location.hostname}.login`
                    );
                    window.localStorage.removeItem("exprTime");
                    window.localStorage.clear();
                    history.push("/");
                    window.location.reload();
                  }}
                >
                  <Link to={""}>

                    Log Out
                  </Link>
                </li>


              </>
              :
              <>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/login-register"}>Login</Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/login-register?register=true"}>
                    Register
                  </Link>
                </li>


              </>

            }


          </ul>
        </div>
      </div> */}
      {/* <div className="same-style header-compare">
        <Link to={process.env.PUBLIC_URL + "/compare"}>
          <i className="pe-7s-shuffle" />
          <span className="count-style">
            {compareData && compareData.length ? compareData.length : 0}
          </span>
        </Link>
      </div>
      <div className="same-style header-wishlist">
        <Link to={process.env.PUBLIC_URL + "/wishlist"}>
          <i className="pe-7s-like" />
          <span className="count-style">
            {wishlistData && wishlistData.length ? wishlistData.length : 0}
          </span>
        </Link>
      </div> */}
      {/* <div className="same-style cart-wrap d-none d-lg-block">
        <button className="icon-cart" onClick={e => handleClick(e)}>
          <i className="pe-7s-shopbag" onClick={() => setcopen(true)} />
          <span className="count-style">
            {cart}
          </span>
        </button>
       
        <MenuCart
          cartData={cartData}
          currency={currency}
          deleteFromCart={deleteFromCart}
          copen={copen}
          setTotal={setCart}
        />
      </div>
      <div className="same-style cart-wrap d-block d-lg-none">
        <Link className="icon-cart" to={process.env.PUBLIC_URL + "/cart"}>
          <i className="pe-7s-shopbag" onClick={() => setcopen(true)} />
          <span className="count-style" >
            {cart}
          </span>
        </Link>
      </div> */}
      <div className="product-details-content2 d-none d-lg-block"
       
      >

        <div className="pro-details-quality">
          <div className="pro-details-cart btn-hover ml-0">
            <a
              href="https://www.ozcleaningsupplies.com.au"
              rel="noopener noreferrer"
              target="_blank"
            >
              Shop with OZ Rentals
            </a>
          </div>

        </div>

      </div>
      <div className="same-style mobile-off-canvas d-block d-lg-none">
        <button
          className="mobile-aside-button"
          onClick={() => triggerMobileMenu()}
        >
          <i className="pe-7s-menu" />
        </button>
      </div>
    </div>
  );
};

IconGroup.propTypes = {
  cartData: PropTypes.array,
  compareData: PropTypes.array,
  currency: PropTypes.object,
  iconWhiteClass: PropTypes.string,
  deleteFromCart: PropTypes.func,
  wishlistData: PropTypes.array
};

const mapStateToProps = state => {
  return {
    currency: state.currencyData,
    cartData: state.cartData,
    wishlistData: state.wishlistData,
    compareData: state.compareData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    deleteFromCart: (item, addToast) => {
      dispatch(deleteFromCart(item, addToast));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IconGroup);
