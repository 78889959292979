import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { FONTS_INTER } from "../../Constant";
import { IMAGE_SERVER_URL_DEV } from "../../Constant/System";

const BannerTwentyFourSingle = ({ data, spaceBottomClass }) => {
  return (
    <div className="col-lg-4 col-md-4">
      <div
        className={`single-banner ${spaceBottomClass ? spaceBottomClass : ""}`}
      >
        <Link to={process.env.PUBLIC_URL + "/shop-grid-standard"}>
          {/* <img src={process.env.PUBLIC_URL + data.image} alt="" /> */}
          <img src={`${IMAGE_SERVER_URL_DEV}${data?.image}`} />
        </Link>
        <div className="banner-content">
          <span style={{color:data.colour,fontFamily:FONTS_INTER,fontSize:"18pt",marginLeft:"-2px"}} >{data.caption1}</span>
          <p style={{color:data.colour,fontFamily:FONTS_INTER,fontSize:"14pt"}} >
            {data.caption2} 
            {/* <span>{data.price}</span> */}
          </p>
          {/* <Link to={"/shop-grid-standard"}>
            <i className="fa fa-long-arrow-right" />
          </Link> */}
        </div>
      </div>
    </div>
  );
};

BannerTwentyFourSingle.propTypes = {
  data: PropTypes.object,
  spaceBottomClass: PropTypes.string
};

export default BannerTwentyFourSingle;
