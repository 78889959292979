import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { IMAGE_SERVER_URL_DEV } from "../../Constant/System";

const BlogFeaturedThreeSingle = ({ singlePost }) => {
  return (
    <div className="col-lg-4 col-sm-6">
      <div className="blog-wrap mb-30 scroll-zoom">
        <div className="blog-img">
          <Link to={process.env.PUBLIC_URL + "/shop-grid-standard"}>
            <img src={process.env.PUBLIC_URL + singlePost.image} alt="" />
            {/* <img src={`${IMAGE_SERVER_URL_DEV}${singlePost?.image}`} /> */}
          </Link>
          <div className="blog-category-names blog-category-names--style2">
            {/* {singlePost.category.map((singleCategory, key) => {
              return (
                <span className="red" key={key}>
                  {singleCategory}
                </span>
              );
            })} */}
          </div>
        </div>

        {singlePost?.title || singlePost?.author ?

        <>
           <div className="blog-content-wrap">
          <div className="blog-content blog-content--style2 text-center">
            <h3>
              {/* <Link to={process.env.PUBLIC_URL + singlePost.url}>
                {singlePost.title}
              </Link> */}
               {singlePost?.title}
            </h3>
            <span>
              {/* By{" "}
              <Link to={process.env.PUBLIC_URL + singlePost.authorUrl}>
                {singlePost.author}
              </Link> */}
               {singlePost?.author}
            </span>
          </div>
        </div>
        </>
      :
      <></>

        }
     
      </div>
    </div>
  );
};

BlogFeaturedThreeSingle.propTypes = {
  singlePost: PropTypes.object
};

export default BlogFeaturedThreeSingle;
