import React from "react";
import Swiper from "react-id-swiper";
import sliderData from "../../data/hero-sliders/hero-slider-twenty-four.json";
import HeroSliderTwentyFourSingle from "../../components/hero-slider/HeroSliderTwentyFourSingle.js";
import { getCover } from "../../Services/API/Get";

const HeroSliderTwentyFour = () => {
  const params = {
    effect: "fade",
    loop: true,
    speed: 1000,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    watchSlidesVisibility: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev"
    },
    renderPrevButton: () => (
      <button className="swiper-button-prev ht-swiper-button-nav">
        <i className="pe-7s-angle-left" />
      </button>
    ),
    renderNextButton: () => (
      <button className="swiper-button-next ht-swiper-button-nav">
        <i className="pe-7s-angle-right" />
      </button>
    )
  };

  const [slides, setSlides] = React.useState([]);
  const [lopen, setlopen] = React.useState(false);

  React.useEffect(() => {
    getSlides();
  }, []);

  const getSlides = () => {
    setlopen(true);
    getCover()
      .then((res) => {
        console.log("COver res>>>>", res.data)
        setlopen(false);
        setSlides(res.data);
      })
      .catch((error) => {
        console.log("cover error", error.response)
        setlopen(false);

      });


  };

  return (
    <>
    {slides.length > 0 ? 

      <div className="slider-area">
      <div className="slider-active nav-style-1">
        <Swiper {...params}>
          {slides &&
            slides.map((single, key) => {
              return (
                <HeroSliderTwentyFourSingle
                  data={single}
                  key={key}
                  sliderClass="swiper-slide"
                />
              );
            })}
        </Swiper>
      </div>
    </div>
      :
      <></>

    }
   </>
  );
};

export default HeroSliderTwentyFour;
