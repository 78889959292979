import PropTypes from "prop-types";
import React from "react";
import { IMAGE_SERVER_URL_DEV } from "../../Constant/System";

const FeatureIconFourSingle = ({ data, spaceBottomClass,index }) => {
  const bg = [
    { backgroundColor: "#ccfbe9" },
    { backgroundColor: "#f2fbcc" },
    { backgroundColor: "#ddfbcc" },

  ]
  return (
    <div className="col-lg-4 col-md-6 col-sm-6">
      <div
        className={`support-wrap-3 text-center ${
          spaceBottomClass ? spaceBottomClass : ""
        }`}
        style={{ backgroundColor: `${bg[index]?.backgroundColor}` }}
      >
        <div className="support-icon-2">
          <img
            className="animated"
            // src={process.env.PUBLIC_URL + data.image}
            src={`${IMAGE_SERVER_URL_DEV}${data?.image}`}
            alt=""
          />
        </div>
        <div className="support-content-3">
          {/* <img src={process.env.PUBLIC_URL + data.titleImage} alt="" /> */}
          <p>{data.caption1}</p>
          <p>{data.caption2}</p>
        </div>
      </div>
    </div>
  );
};

FeatureIconFourSingle.propTypes = {
  data: PropTypes.object,
  spaceBottomClass: PropTypes.string
};

export default FeatureIconFourSingle;
