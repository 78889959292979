import PropTypes from "prop-types";
import React, { Fragment, useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { getDiscountPrice } from "../../helpers/product";
import Rating from "./sub-components/ProductRating";
import ProductModal from "./ProductModal";
import { IMAGE_SERVER_URL_DEV } from "../../Constant/System";
import { CurrentUser, getProductByName } from "../../Services/API/Get";
import _ from "lodash";
import { postQuote } from "../../Services/API/Post";
import LoadingSpinner from "../../components/LoadingSpinner/loadingSpinner";
import AuthContext from "../../Context/AuthContext";

const ProductGridSingle = ({
  product,
  currency,
  addToCart,
  addToWishlist,
  addToCompare,
  cartItem,
  wishlistItem,
  compareItem,
  sliderClassName,
  spaceBottomClass,
  sCategory
}) => {
  const [modalShow, setModalShow] = useState(false);
  const { addToast } = useToasts();
  const history = useHistory();
  // const [thumblineImage, setthumblineImage] = useState("");
  const { user, setUser } = useContext(AuthContext);

  const [currUser, setcurrUser] = useState(JSON.parse(localStorage.getItem(`${window.location.hostname}.userDetails`)));
  const [lopen, setlopen] = useState(false);

  // const discountedPrice = getDiscountPrice(product.price, product.discount);
  // const finalProductPrice = +(product.price * currency.currencyRate).toFixed(2);
  // const finalDiscountedPrice = +(
  //   discountedPrice * currency.currencyRate
  // ).toFixed(2);

  // let unitPrice = JSON.parse(product.priceDetails).length > 0 ? JSON.parse(product.priceDetails)[0].unitPrice : 0
  // let discount = product.discount;

  const thumblineImage = JSON.parse(product.images)?.filter((item) => item?.thumbnail === true)[0];
  



  // const finalProductPrice = unitPrice?.toFixed(2)
  // const discountedPrice = discount !== 0 ? parseFloat(unitPrice * discount / 100).toFixed(2) : parseFloat(unitPrice * 6 / 100).toFixed(2)
  // const finalDiscountedPrice = discount !== 0 ? parseFloat(unitPrice * (100 - discount) / 100).toFixed(2) : parseFloat(unitPrice * 6 / 100).toFixed(2)

  const finalProductPrice = 0
  const discountedPrice = 0
  const finalDiscountedPrice = 0

  // const thumblineImage = JSON.parse(
  //   product.images
  // ).filter((item) => item.thumbnail === true)[0];


  const getProduct = (name, id, category, info) => {


    getProductByName({
      name: name,
    })
      .then((res) => {
        // setlopen(false);
        let colorsof = [];
        let sizeOf = [];
        let imageOf = [];
        let tumblineOf = "";
        let imageOf2 = [];
        let materialOf = [];
        const mobile = _.uniqBy(res?.data, function (e) {
          return e.colour;
        });

        const mobile2 = _.uniqBy(res?.data, function (e) {
          return e.size;
        });

        const mobile3 = _.uniqBy(res?.data, function (e) {
          return e.meterial;
        });

        colorsof = mobile.map((data, index) => {
          return {
            id: `colors${index}`,
            status: index === 0 ? true : false,
            color: `${data?.colour}`.trim().toString(),
            data: data,
          };
        });

        sizeOf = mobile2.map((data, index) => {
          return {
            id: `size${index}`,
            status: index === 0 ? true : false,
            size: `${data?.size}`.trim().toString(),
            data: data,
          };
        });

        materialOf = mobile3.map((data, index) => {
          return {
            id: `material${index}`,
            status: index === 0 ? true : false,
            material: `${data?.meterial}`.trim().toString(),
            data: data,
          };
        });

        const newArr = res?.data?.map((item) => {
          return {
            images: JSON.parse(item.images),
          };

        });

        imageOf = JSON.parse(res?.data[0].images);
        var imgArr = []
        imageOf2 = newArr?.map((item) => {

          const imageOf3 = item.images.length > 0 && item.images?.map((item2) => {

            imgArr.push(item2);
            return {
              original: `${IMAGE_SERVER_URL_DEV}${item2.path}`,
              thumbnail: `${IMAGE_SERVER_URL_DEV}${item2.path}`,
            };
          });

          return imageOf3;

        });

        tumblineOf = imgArr?.filter((item) => item?.thumbnail === true)[0];

        // setthumblineImage(tumblineOf);

        console.log("DSDSDSD",tumblineOf)


        let def = Object.assign({}, info);

        def.variation = colorsof
        def.sizes = sizeOf
        def.nImages = imgArr
        // def.productId = proId
        def.materials = materialOf
        def.categoryName = category


      })
      .catch((error) => {
        // setlopen(false);
        console.log("error", error);
      });


  }
  const getCurrentUser = () => {
    CurrentUser()
      .then((res) => {
        if (res.statusCode === 200) {
          const cData = res.data[0];
          const obj = {
            id: cData.id,
            email: cData?.email,
            firstName: cData?.firstName,
            lastName: cData?.lastName,
            phone: cData?.mobileNumber,
          };
          //   this.setState({
          //     firstName: cData?.firstName,
          //     lastName: cData?.lastName,
          //     email: cData?.email,
          //     tele: cData?.phone,
          //   });
          setcurrUser(cData);
          // setName(cData?.firstName + " " + cData?.lastName);

          localStorage.setItem(
            `${window.location.hostname}.profile`,
            JSON.stringify(obj)
          );
        }
      })
      .catch((error) => {
        console.log("error", error);
        console.log("current user error", error.response.data.message);
        setcurrUser(null);
      });
  };

  const reqAQUote = () => {

    let currUser = JSON.parse(localStorage.getItem(`${window.location.hostname}.userDetails`))

    let obj = {
      name: currUser?.firstName + " " + currUser?.lastName,
      email: currUser?.email,
      phone: currUser?.mobileNumber,
      userId: currUser?.id,
      message: "Request a Quote",
      product: product?.productName,
      productId: product?.productId,
      size: product?.size,
      material: product?.meterial,
      firstName: currUser?.firstName,
      lastName: currUser?.lastName,
    }

    console.log("PPPPPPP", currUser)

    if (currUser !== null) {
      setlopen(true);
      postQuote(obj)
        .then((res) => {
          setlopen(false);
          addToast("Quote Requested Successfully", { appearance: "success", autoDismiss: true });
        })
        .catch((error) => {
          setlopen(false);
          console.log("error", error);
          addToast(error.response.data.message, { appearance: "error", autoDismiss: true });
          setcurrUser(null);
        });

    } else {
      history.push("/login-register");
      window.scroll(0, 0);

    }


  };

  React.useEffect(() => {
    getProduct(product.productName, product.id, product.categoryName, product,sCategory);
    // getCurrentUser();

  }, []);

  var lowest = Number.POSITIVE_INFINITY;
  var highest = Number.NEGATIVE_INFINITY;
  var tmp;
  var myArray = JSON.parse(product?.priceDetails);
  for (var i = myArray.length - 1; i >= 0; i--) {
    if (myArray[i].unitPrice !== 0) {


      tmp = myArray[i].unitPrice;
      if (tmp < lowest) lowest = tmp;
      if (tmp > highest) highest = tmp;
    }
  }



  return (
    <Fragment>
      

      <LoadingSpinner open={lopen} />

      <div
        className={`col-xl-3 col-md-6 col-lg-4 col-sm-6 ${sliderClassName ? sliderClassName : ""
          }`}
      >
        <div
          className={`product-wrap ${spaceBottomClass ? spaceBottomClass : ""}`}
        >
          <div className="product-img">
            {product?.size === "" && product?.meterial === "" && JSON.parse(product?.priceDetails).length === 0 ?
              <>
                <img
                  style={{
                    minWidth: 70,
                    height: "350px",
                    overflow: "hidden",
                    position: "relative",
                    objectFit: "cover"
                  }}
                  className="default-img"
                  // src={JSON.parse(product.images).length > 0 ?`${IMAGE_SERVER_URL_DEV}${thumblineImage.path}`:""}
                  src={thumblineImage ? `${IMAGE_SERVER_URL_DEV}${thumblineImage?.path}` : ""}
                  alt=""
                />
              </>
              :
              <>
                <Link to={process.env.PUBLIC_URL + "/product/" + product.productId}>
                  <img
                    style={{
                      minWidth: 70,
                      height: "350px",
                      overflow: "hidden",
                      position: "relative",
                      objectFit: "cover"
                    }}
                    className="default-img"
                    // src={JSON.parse(product.images).length > 0 ?`${IMAGE_SERVER_URL_DEV}${thumblineImage.path}`:""}
                    src={thumblineImage ? `${IMAGE_SERVER_URL_DEV}${thumblineImage?.path}` : ""}
                    alt=""
                  />
                  {/* <img
                className="default-img"
                src={process.env.PUBLIC_URL + product.image[0]}
                alt=""
              /> */}
                  {/* {product.image.length > 1 ? (
                <img
                  className="hover-img"
                  src={process.env.PUBLIC_URL + product.image[1]}
                  alt=""
                />
              ) : (
                ""
              )} */}
                </Link>

              </>

            }

            {product?.stock === 0 ? (
              <div className="product-img-badges">
                {product.discount ? (
                  <span className="pink">-{product.discount}%</span>
                ) : (
                  ""
                )}
                {product.new ? <span className="purple">New</span> : ""}
                {product?.stock === 0 ? <span className="purple">Out of Stock</span> : ""}
              </div>
            ) : (
              ""
            )}
            {product?.size === "" && product?.meterial === "" && JSON.parse(product?.priceDetails).length === 0 ?

              <>
                <div className="product-action">
                  <div className="pro-same-action pro-wishlist">
                    {/* <button
                      className={wishlistItem !== undefined ? "active" : ""}
                      disabled={wishlistItem !== undefined}
                      title={
                        wishlistItem !== undefined
                          ? "Added to wishlist"
                          : "Add to wishlist"
                      }
                      onClick={() => addToWishlist(product, addToast)}
                    >
                      <i className="pe-7s-like" />
                    </button> */}
                  </div>
                  <div className="pro-same-action pro-cart">
                    <button className="active"
                      onClick={(e) => { reqAQUote() }}
                    >
                      <i className="pe-7s-note"></i>{" "}
                      Request a quote
                    </button>
                  </div>
                  <div className="pro-same-action pro-quickview">
                    {/* <button onClick={() => setModalShow(true)} title="Quick View">
                      <i className="pe-7s-look" />
                    </button> */}
                  </div>
                </div>

              </>
              :
              <></>


            }

            {/* <div className="product-action">
              <div className="pro-same-action pro-wishlist">
                <button
                  className={wishlistItem !== undefined ? "active" : ""}
                  disabled={wishlistItem !== undefined}
                  title={
                    wishlistItem !== undefined
                      ? "Added to wishlist"
                      : "Add to wishlist"
                  }
                  onClick={() => addToWishlist(product, addToast)}
                >
                  <i className="pe-7s-like" />
                </button>
              </div>
              <div className="pro-same-action pro-cart">
                {product.affiliateLink ? (
                  <a
                    href={product.affiliateLink}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {" "}
                    Buy now{" "}
                  </a>
                ) : product.variation && product.variation.length >= 1 ? (
                  <Link to={`${process.env.PUBLIC_URL}/product/${product.id}`}>
                    Select Option
                  </Link>
                ) : product.stock && product.stock > 0 ? (
                  <button
                    onClick={() => addToCart(product, addToast)}
                    className={
                      cartItem !== undefined && cartItem.quantity > 0
                        ? "active"
                        : ""
                    }
                    disabled={cartItem !== undefined && cartItem.quantity > 0}
                    title={
                      cartItem !== undefined ? "Added to cart" : "Add to cart"
                    }
                  >
                    {" "}
                    <i className="pe-7s-cart"></i>{" "}
                    {cartItem !== undefined && cartItem.quantity > 0
                      ? "Added"
                      : "Add to cart"}
                  </button>
                ) : (
                  <button disabled className="active">
                    Out of Stock
                  </button>
                )}
              </div>
              <div className="pro-same-action pro-quickview">
                <button onClick={() => setModalShow(true)} title="Quick View">
                  <i className="pe-7s-look" />
                </button>
              </div>
            </div> */}
          </div>
          <div className="product-content text-center">
            <h3>
              <Link to={process.env.PUBLIC_URL + "/product/" + product.productId}>
                {product.productName}
              </Link>
            </h3>
            {product.rating && product.rating > 0 ? (
              <div className="product-rating">
                <Rating ratingValue={product.rating} />
              </div>
            ) : (
              ""
            )}
            {JSON.parse(product.priceDetails).length > 0 ?


              <>
                <div className="product-price">

                  {/* <p>{`${JSON.parse(product.priceDetails)[0].qty}`}</p> */}
                  {
                    lowest !== highest ?

                      <>
                        {/* <span>{currency.currencySymbol + lowest.toFixed(2)}</span>{" "}
                        <span>{currency.currencySymbol + highest.toFixed(2)}</span>{" "} */}
                      </>
                      :
                      // <span>{currency.currencySymbol + lowest.toFixed(2)}</span>
                        <></>
                  }

                </div>
                <div className="product-price">
                  {/* {product.discount !== 0 ? (
                    <Fragment>
                      <span>{currency.currencySymbol + finalDiscountedPrice}</span>{" "}
                      <span className="old">
                        {currency.currencySymbol + finalProductPrice}
                      </span>
                    </Fragment>
                  ) : (
                    <span>{currency.currencySymbol + finalProductPrice} </span>
                  )} */}
                  {/* {discountedPrice !== null ? (
      <Fragment>
        <span>{currency.currencySymbol + finalDiscountedPrice}</span>{" "}
        <span className="old">
          {currency.currencySymbol + finalProductPrice}
        </span>
      </Fragment>
    ) : (
      <span>{currency.currencySymbol + finalProductPrice} </span>
    )} */}
                </div>


              </>

              :

              <></>



            }

          </div>
        </div>
      </div>
      {/* product modal */}
      <ProductModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        product={product}
        currency={currency}
        discountedprice={discountedPrice}
        finalproductprice={finalProductPrice}
        finaldiscountedprice={finalDiscountedPrice}
        cartitem={cartItem}
        wishlistitem={wishlistItem}
        compareitem={compareItem}
        addtocart={addToCart}
        addtowishlist={addToWishlist}
        addtocompare={addToCompare}
        addtoast={addToast}
      />
    </Fragment>
  );
};

ProductGridSingle.propTypes = {
  addToCart: PropTypes.func,
  addToCompare: PropTypes.func,
  addToWishlist: PropTypes.func,
  cartItem: PropTypes.object,
  compareItem: PropTypes.object,
  currency: PropTypes.object,
  product: PropTypes.object,
  sliderClassName: PropTypes.string,
  spaceBottomClass: PropTypes.string,
  wishlistItem: PropTypes.object
};

export default ProductGridSingle;
