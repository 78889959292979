import PropTypes from "prop-types";
import React ,{useState}from "react";
import blogFeaturedThreeData from "../../data/blog-featured/blog-featured.json";
import BlogFeaturedThreeSingle from "../../components/blog-featured/BlogFeaturedThreeSingle";
import SectionTitle from "../../components/section-title/SectionTitle";
import { getBanner } from "../../Services/API/Get";
import LoadingSpinner from "../../components/LoadingSpinner/loadingSpinner";

const BlogFeaturedThree = ({ spaceTopClass, spaceBottomClass }) => {
  const [caption, setcaption] = useState([]);
  const [lopen, setlopen] = useState(false);

  const captionGet = () => {
    setlopen(true);
    getBanner()
    .then((res) => {
      setlopen(false);
      console.log("about>>",res.data);
      setcaption(res.data);
      // setLogo(res.data[res.data.length - 1].image)
      // this.setState({
      //   logo: res.data[res.data.length - 1].image,
      // });
    })
    .catch((error) => {
      setlopen(false);
      console.error(error);
    });
  };



  React.useEffect(() => {
    captionGet();
    // coverGet();
  }, []);
  return (
    <>
     <LoadingSpinner open={lopen} />
    <div
      className={`blog-area ${spaceTopClass ? spaceTopClass : ""} ${
        spaceBottomClass ? spaceBottomClass : ""
      }`}
    >
      <div className="container">
        <SectionTitle
          titleText="Latest News"
          subtitleText="But I must explain to you how all this mistaken idea of denouncing."
          positionClass="text-center"
          spaceClass="mb-55"
          borderClass="no-border"
        />
        <div className="row">
          {blogFeaturedThreeData.length > 0 && blogFeaturedThreeData.map(singlePost => {
            return (
              <BlogFeaturedThreeSingle
                singlePost={singlePost}
                key={singlePost.id}
              />
            );
          })}
        </div>
      </div>
    </div>
    </>
  );
};

BlogFeaturedThree.propTypes = {
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string
};

export default BlogFeaturedThree;
