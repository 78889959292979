import PropTypes from "prop-types";
import React from "react";
import { IMAGE_SERVER_URL_DEV } from "../../Constant/System";

const TestimonialOneSingle = ({ data, sliderClass, testimonialClass }) => {
  return (
    <div
      className={`${testimonialClass ? testimonialClass : "single-testimonial"
        } text-center ${sliderClass ? sliderClass : ""}`}
    >
      {/* <img src={process.env.PUBLIC_URL + data.image} alt="" /> */}
      <img src={`${IMAGE_SERVER_URL_DEV}${data?.image}`} />
      <p>{data.description}</p>
      <div className="client-info">
        <i className="fa fa-map-signs" />
        <h5>{data.name}</h5>
        <span>{data.job}</span><br />
        <span>{data.city}</span>
      </div>
    </div>
  );
};

TestimonialOneSingle.propTypes = {
  data: PropTypes.object,
  sliderClass: PropTypes.string
};

export default TestimonialOneSingle;
