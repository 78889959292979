import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { IMAGE_SERVER_URL_DEV } from "../../Constant/System";

const BannerTwentyThreeSingle = ({ data, spaceBottomClass }) => {
  return (
    <div className="col-sm-6 col-12">
      <div
        className={`single-banner ${spaceBottomClass ? spaceBottomClass : ""}`}
      >
        <Link to={process.env.PUBLIC_URL + "/shop-grid-standard"}>
          {/* <img src={process.env.PUBLIC_URL + data.image} alt="" /> */}
          <img src={`${IMAGE_SERVER_URL_DEV}${data?.image}`} alt="" />
         
        </Link>
      </div>
    </div>
  );
};

BannerTwentyThreeSingle.propTypes = {
  data: PropTypes.object,
  spaceBottomClass: PropTypes.string
};

export default BannerTwentyThreeSingle;
