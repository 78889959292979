import React,{useState} from "react";
import { getCompany } from "../../../Services/API/Get";

const MobileWidgets = () => {

  const [company, setcompany] = useState("");

  const [lopen, setlopen] = useState(false);

  const CompanyGet = () => {
    setlopen(true);
    getCompany()
    .then((res) => {
      setlopen(false);
      console.log(res.data);
      setcompany(res.data[res.data.length - 1]);
      // setLogo(res.data[res.data.length - 1].image)
      // this.setState({
      //   logo: res.data[res.data.length - 1].image,
      // });
    })
    .catch((error) => {
      setlopen(false);
      console.error(error);
    });
  };


  React.useEffect(() => {
    CompanyGet();

   
  }, []);
  return (
    <div className="offcanvas-widget-area">
      <div className="off-canvas-contact-widget">
        <div className="header-contact-info">
          <ul className="header-contact-info__list">
            <li>
              <i className="fa fa-phone"></i>{" "}
              {/* <a href="tel://12452456012">(1245) 2456 012 </a> */}
              <a href={`tel:${company.phoneNumber}`}>{company.phoneNumber} </a>
            </li>
            <li style={{whiteSpace:"nowrap"}}>
              <i className="fa fa-envelope"></i>{" "}
              <a href={`mailto:${company.email}`}  >{company.email} </a>
            </li>
          </ul>
        </div>
      </div>
      {/*Off Canvas Widget Social Start*/}
      <div className="off-canvas-widget-social">
        {/* <a href="//twitter.com" title="Twitter">
          <i className="fa fa-twitter"></i>
        </a> */}
        <a href="//instagram.com" title="Instagram">
          <i className="fa fa-instagram"></i>
        </a>
        <a href="//facebook.com" title="Facebook">
          <i className="fa fa-facebook"></i>
        </a>
        {/* <a href="//pinterest.com" title="Pinterest">
          <i className="fa fa-pinterest"></i>
        </a> */}
      </div>
      {/*Off Canvas Widget Social End*/}
    </div>
  );
};

export default MobileWidgets;
