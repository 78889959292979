import PropTypes from "prop-types";
import React ,{useState}from "react";
import blogFeaturedData from "../../data/blog-featured/blog-featured.json";
import BlogFeaturedSingle from "../../components/blog-featured/BlogFeaturedSingle";
import SectionTitle from "../../components/section-title/SectionTitle";
import { getBanner, getCover } from "../../Services/API/Get";
import LoadingSpinner from "../../components/LoadingSpinner/loadingSpinner";

const BlogFeatured = ({ spaceTopClass, spaceBottomClass }) => {
  const [caption, setcaption] = useState([]);
  const [lopen, setlopen] = useState(false);

  const captionGet = () => {
    setlopen(true);
    getBanner()
    .then((res) => {
      setlopen(false);
      console.log("about>>",res.data);
      setcaption(res.data);
      // setLogo(res.data[res.data.length - 1].image)
      // this.setState({
      //   logo: res.data[res.data.length - 1].image,
      // });
    })
    .catch((error) => {
      setlopen(false);
      console.error(error);
    });
  };

  const coverGet = () => {
    setlopen(true);
    getCover()
    .then((res) => {
      setlopen(false);
      console.log("about>>",res.data);
      setcaption(res.data);
      // setLogo(res.data[res.data.length - 1].image)
      // this.setState({
      //   logo: res.data[res.data.length - 1].image,
      // });
    })
    .catch((error) => {
      setlopen(false);
      console.error(error);
    });
  };

  React.useEffect(() => {
    captionGet();
    // coverGet();
  }, []);
  return (
    <>
           <LoadingSpinner open={lopen} />
    <div
      className={`blog-area ${spaceTopClass ? spaceTopClass : ""} ${
        spaceBottomClass ? spaceBottomClass : ""
      }`}
    >
      <div className="container">
      <SectionTitle
          titleText="Latest News"
          subtitleText="But I must explain to you how all this mistaken idea of denouncing."
          positionClass="text-center"
          spaceClass="mb-55"
          borderClass="no-border"
        />
        <div className="row" style={{display:"flex",justifyContent:"center"}} >
          {caption.length > 0 && caption?.map(singlePost => {
            return (
              <BlogFeaturedSingle singlePost={singlePost} key={singlePost.id} />
            );
          })}
        </div>
      </div>
    </div>
    </>
  );
};

BlogFeatured.propTypes = {
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string
};

export default BlogFeatured;
