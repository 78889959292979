import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { getProducts, setActiveSort } from "../../helpers/product";
import ProductGridSingle from "../../components/product/ProductGridSingle";
import { addToCart } from "../../redux/actions/cartActions";
import { addToWishlist } from "../../redux/actions/wishlistActions";
import { addToCompare } from "../../redux/actions/compareActions";
import { getAllProducts } from "../../Services/API/Get";

const ProductGrid = ({
  products,
  currency,
  addToCart,
  addToWishlist,
  addToCompare,
  cartItems,
  wishlistItems,
  compareItems,
  sliderClassName,
  spaceBottomClass,
  sCategory
}) => {
  const [allProducts, setallProducts] = React.useState([]);

  const getNewArrival = () => {

    console.log("UUUUUUU>>>>",sCategory)
    
    getAllProducts({
      pto: Number.MAX_SAFE_INTEGER,
      pfrom: "0",
      sort: {
        sortField: "displayName",
        sort: "asc",
      },
      pNo: "1",
      limit: sCategory !== "all" ? "16" : Number.MAX_SAFE_INTEGER.toString(),
      // latest: 1,

      manufacturer: JSON.stringify([]),
      categories: sCategory !== "" && sCategory !== "all" ? JSON.stringify([sCategory]) : JSON.stringify([]),
    })
      .then((res) => {
        console.log("this is from new arrival before", res.data.result);

        if (res?.data?.result?.length !== 0) {
          setallProducts(res.data.result)
          // this.setState(
          //   {
          //     array: res.data.result,
          //     showOff: true,
          //   },
          //   () => console.log("this is from new arrival after", this.state.array)
          // );
        }
      })
      .catch((error) => {
        console.error(">errorrrrr>", error);
        setallProducts([])
        // this.setState({
        //   array: [],
        //   showOff: false,
        // });
      });
   
  };

  React.useEffect(() => {
    getNewArrival();
   
  }, [sCategory]);

  




  return (
    <Fragment>
      {allProducts.length>0 && allProducts.map(product => {
        return (
          <ProductGridSingle
            sliderClassName={sliderClassName}
            spaceBottomClass={spaceBottomClass}
            product={product}
            // newProducts={allProducts}
            currency={currency}
            addToCart={addToCart}
            addToWishlist={addToWishlist}
            addToCompare={addToCompare}
            cartItem={
              cartItems.filter(cartItem => cartItem.id === product.id)[0]
            }
            wishlistItem={
              wishlistItems.filter(
                wishlistItem => wishlistItem.id === product.id
              )[0]
            }
            compareItem={
              compareItems.filter(
                compareItem => compareItem.id === product.id
              )[0]
            }
            key={product.id}
            sCategory={sCategory}
          />
        );
      })}
    </Fragment>
  );
};

ProductGrid.propTypes = {
  addToCart: PropTypes.func,
  addToCompare: PropTypes.func,
  addToWishlist: PropTypes.func,
  cartItems: PropTypes.array,
  compareItems: PropTypes.array,
  currency: PropTypes.object,
  products: PropTypes.array,
  sliderClassName: PropTypes.string,
  spaceBottomClass: PropTypes.string,
  wishlistItems: PropTypes.array
};

const mapStateToProps = (state, ownProps) => {
  return {
    products: getProducts(
      state.productData.products,
      ownProps.category,
      ownProps.type,
      ownProps.limit
    ),
    currency: state.currencyData,
    cartItems: state.cartData,
    wishlistItems: state.wishlistData,
    compareItems: state.compareData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addToCart: (
      item,
      addToast,
      quantityCount,
      selectedProductColor,
      selectedProductSize
    ) => {
      dispatch(
        addToCart(
          item,
          addToast,
          quantityCount,
          selectedProductColor,
          selectedProductSize
        )
      );
    },
    addToWishlist: (item, addToast) => {
      dispatch(addToWishlist(item, addToast));
    },
    addToCompare: (item, addToast) => {
      dispatch(addToCompare(item, addToast));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductGrid);
