import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { IMAGE_SERVER_URL_DEV } from "../../Constant/System";

const Logo = ({ imageUrl, logoClass }) => {
  return (
    <div className={`${logoClass ? logoClass : ""}`}>
      <Link to={process.env.PUBLIC_URL + "/"}>
        <img alt="" 
        // src={process.env.PUBLIC_URL + imageUrl} 
        src= {`${IMAGE_SERVER_URL_DEV}${imageUrl}`}
        style={{width:"200px",height:"70px",marginTop:"-12%",marginBottom:"5%"}}/>
      </Link>
    </div>
  );
};

Logo.propTypes = {
  imageUrl: PropTypes.string,
  logoClass: PropTypes.string
};

export default Logo;
