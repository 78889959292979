import PropTypes from "prop-types";
import React from "react";
import bannerData from "../../data/banner/banner-twenty-three.json";
import BannerTwentyThreeSingle from "../../components/banner/BannerTwentyThreeSingle.js";
import { getCaption } from "../../Services/API/Get";

const BannerTwentyThree = ({ spaceTopClass, spaceBottomClass }) => {

  const [slides, setSlides] = React.useState([]);
  const [lopen, setlopen] = React.useState(false);

  React.useEffect(() => {
    getSlides();
  }, []);

  const getSlides = () => {
    setlopen(true);
    getCaption()
      .then((res) => {
        console.log("COver res>>>>", res.data)
        setlopen(false);
        setSlides(res.data);
      })
      .catch((error) => {
        console.log("cover error", error.response)
        setlopen(false);

      });


  };
  return (
    <>
    {
      slides.length > 0 ? 

      <div
      className={`banner-area ${spaceTopClass ? spaceTopClass : ""} ${
        spaceBottomClass ? spaceBottomClass : ""
      }`}
    >
      <div className="container">
        <div className="row">
          {slides &&
            slides.map((single, key) => {
              return <BannerTwentyThreeSingle data={single} key={key} />;
            })}
        </div>
      </div>
    </div>
      :
      <></>
    }
    </>
 
  );
};

BannerTwentyThree.propTypes = {
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string
};

export default BannerTwentyThree;
