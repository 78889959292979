import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { IMAGE_SERVER_URL_DEV } from "../../Constant/System";

const BlogFeaturedSingle = ({ singlePost }) => {
  return (
    <div className="col-lg-4 col-sm-6">
      <div className="blog-wrap mb-30 scroll-zoom">
        <div className="blog-img">
          <Link to={process.env.PUBLIC_URL + "/shop-grid-standard"}>
            {/* <img src={process.env.PUBLIC_URL + singlePost.image} alt="" /> */}
            <img src={`${IMAGE_SERVER_URL_DEV}${singlePost?.image}`} />
          </Link>
          <div className="blog-category-names">
            {/* {singlePost.category.map((singleCategory, key) => {
              return (
                <span className="purple" key={key}>
                  {singleCategory}
                </span>
              );
            })} */}
          </div>
        </div>
        {singlePost?.caption1 || singlePost?.caption2 ?

          <>
            <div className="blog-content-wrap">
              <div className="blog-content text-center">
                <h3>
                  {/* <Link to={process.env.PUBLIC_URL + singlePost.url}>
                {singlePost.title}
              </Link> */}
                  {singlePost?.caption1}
                </h3>
                <span>
                  {singlePost?.caption2}
                  {/* By{" "}
              <Link to={process.env.PUBLIC_URL + singlePost.authorUrl}>
                {singlePost.author}
              </Link> */}
                </span>
              </div>
            </div>


          </>
          :
          <></>



        }

      </div>
    </div>
  );
};

BlogFeaturedSingle.propTypes = {
  singlePost: PropTypes.object
};

export default BlogFeaturedSingle;
