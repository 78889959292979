import PropTypes from "prop-types";
import React from "react";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import SectionTitle from "../../components/section-title/SectionTitle";
import ProductGrid from "./ProductGrid";
import { Link } from "react-router-dom";
import { Categories } from "../../Services/API/Get";
import { useState } from "react";
import { useEffect } from "react";

const TabProduct = ({
  spaceTopClass,
  spaceBottomClass,
  bgColorClass,
  category
}) => {

  const [categories, setcategories] = useState([]);
  const [cKey, setcKey] = useState("all");

  useEffect(() => {

    getallCategory();

  }, []);


  const getallCategory = () => {

    Categories()
      .then((response) => {
        console.log("category>>>>", response?.data);
        setcategories(response.data);
        // setcKey(response.data[0].mainId)
        // this.setState(
        //   {
        //     array: res.data.result,
        //     filter: res.data.result,
        //     showPages: m,
        //     showOff: true,
        //   },
        //   async () => {
        //     await this.getPaginationGroup(m);
        //   }
        // );
      })
      .catch(() => {
        // this.setState({
        //   array: [],
        //   filter: [],
        //   showPages: 1,
        //   showOff: false,
        // });
      });

  }

  return (
    <>
      {
        categories.length > 0 && cKey !== "" ? (
          <div
            className={`product-area ${spaceTopClass ? spaceTopClass : ""} ${spaceBottomClass ? spaceBottomClass : ""
              } ${bgColorClass ? bgColorClass : ""}`}
          >
            <div className="container">
              <SectionTitle titleText="OUR PRODUCTS" positionClass="text-center" />
              <Tab.Container defaultActiveKey={cKey} activeKey={cKey}>
                <Nav
                  variant="pills"
                  className="product-tab-list pt-30 pb-55 text-center"
                >
                       <Nav.Item>
                    <Nav.Link eventKey={"all"}
                      onClick={(e) => {
                        setcKey("all")
                      }
                      }
                    >
                      <h4>{"All Products"}</h4>
                    </Nav.Link>
                  </Nav.Item>
                  {
                    
                    categories && categories.map(item => {

                      return (
                        <Nav.Item>
                          <Nav.Link eventKey={item.mainId}
                            onClick={(e) => {
                              setcKey(item.mainId)
                            }
                            }
                          >
                            <h4>{item.maincategory}</h4>
                          </Nav.Link>
                        </Nav.Item>
                      );
                    })
                  }
             
                  {/* <Nav.Item>
              <Nav.Link eventKey="newArrival">
                <h4>New Arrivals</h4>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="bestSeller">
                <h4>Best Sellers</h4>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="saleItems">
                <h4>Sale Items</h4>
              </Nav.Link>
            </Nav.Item> */}
                </Nav>
                <Tab.Content>
                  {/* {
                    categories && categories.map(item => {

                      return ( */}
                  <>
                    <Tab.Pane eventKey={cKey}>
                      <div className="row">
                        <ProductGrid
                          category={category}
                          type="new"
                          limit={Number.MAX_SAFE_INTEGER}
                          spaceBottomClass="mb-25"
                          sCategory={cKey}
                        />
                      </div>
                    </Tab.Pane>
                  </>
                  {/* );
                    })
                  } */}
                  {/* <Tab.Pane eventKey="newArrival">
                    <div className="row">
                      <ProductGrid
                        category={category}
                        type="new"
                        limit={Number.MAX_SAFE_INTEGER}
                        spaceBottomClass="mb-25"
                      />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="bestSeller">
                    <div className="row">
                      <ProductGrid
                        category={category}
                        type="bestSeller"
                        limit={8}
                        spaceBottomClass="mb-25"
                      />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="saleItems">
                    <div className="row">
                      <ProductGrid
                        category={category}
                        type="saleItems"
                        limit={8}
                        spaceBottomClass="mb-25"
                      />
                    </div>
                  </Tab.Pane> */}
                </Tab.Content>
              </Tab.Container>
              <div className="view-more text-center mt-20 toggle-btn6 col-12">
                <Link
                  className="loadMore6"
                  to={process.env.PUBLIC_URL + "/shop-grid-standard"}
                >
                  VIEW MORE PRODUCTS
                </Link>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )
      }

    </>
  );
};

TabProduct.propTypes = {
  bgColorClass: PropTypes.string,
  category: PropTypes.string,
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string
};

export default TabProduct;
